// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-publication-pages-js": () => import("../src/templates/publicationPages.js" /* webpackChunkName: "component---src-templates-publication-pages-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

